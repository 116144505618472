import { useEffect, useState } from 'react'
import {
  faTiktok,
  faInstagram,
  faPinterest,
  faTwitter,
  faJsSquare,
  faReact,
} from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    return setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <p>
            Étudiant en BUT TC (Techniques de commercialisation) à l'IUT de Caen, 
            je suis passionné par l'univers du digital, de la communication et plus particulièrement
            par la 3D.
          </p>
          <p align="LEFT">
          Je fais beaucoup de choses: des photos, des vidéos, du montage vidéo, du design, de la musique, du codage ...
          J'ai beaucoup voyager, ce qui explique mon inspiration, j'ai appris en voyageant

          </p>
          <p>
            Créer, voyager, créer
          </p>
        </div>

        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faTiktok} color="#DD0031" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faInstagram} color="#F06529" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faTwitter} color="#28A4D9" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faPinterest} color="#EC4D28" />
            </div>
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default About
